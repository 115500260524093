import React from 'react'
import styled from 'styled-components'

const IconSvg = styled.svg`
  fill: ${(props) => props.theme.fontColor};
`
const InstagramIcon = ({ url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <IconSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path d="M 3.1875 0 C 1.386719 0 0 1.386719 0 3.1875 L 0 20.8125 C 0 22.613281 1.386719 24 3.1875 24 L 20.8125 24 C 22.613281 24 24 22.613281 24 20.8125 L 24 3.1875 C 24 1.386719 22.613281 0 20.8125 0 Z M 3.1875 2 L 20.8125 2 C 21.511719 2 22 2.488281 22 3.1875 L 22 8 L 16.9375 8 C 15.675781 6.757813 13.933594 6 12 6 C 10.066406 6 8.324219 6.757813 7.0625 8 L 2 8 L 2 3.1875 C 2 2.488281 2.488281 2 3.1875 2 Z M 17.3125 3 C 17.113281 3 17 3.113281 17 3.3125 L 17 6.6875 C 17 6.886719 17.113281 7 17.3125 7 L 20.6875 7 C 20.886719 7 21 6.886719 21 6.6875 L 21 3.3125 C 21 3.113281 20.886719 3 20.6875 3 Z M 12 8 C 14.800781 8 17 10.199219 17 13 C 17 15.800781 14.800781 18 12 18 C 9.199219 18 7 15.800781 7 13 C 7 10.199219 9.199219 8 12 8 Z M 12 10 C 11.699219 10 11.394531 10.09375 11.09375 10.09375 C 11.59375 10.292969 12 10.898438 12 11.5 C 12 12.300781 11.300781 13 10.5 13 C 9.898438 13 9.394531 12.59375 9.09375 12.09375 C 9.09375 12.394531 9 12.699219 9 13 C 9 14.699219 10.300781 16 12 16 C 13.699219 16 15 14.699219 15 13 C 15 11.300781 13.699219 10 12 10 Z" />
    </IconSvg>
  </a>
)

export default InstagramIcon
