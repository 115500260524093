import React from 'react'
import styled from 'styled-components'

const IconSvg = styled.svg`
  fill: ${(props) => props.theme.fontColor};
`
const FacebookIcon = ({ url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <IconSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path d="M 21.800781 0 L 2.199219 0 C 1 0 0 1 0 2.199219 L 0 21.800781 C 0 23 1 24 2.199219 24 L 12 24 L 12 14 L 9 14 L 9 11 L 12 11 L 12 8 C 12 5.5 13 4 16 4 L 19 4 L 19 7 L 17.699219 7 C 16.800781 7 16 7.800781 16 8.699219 L 16 11 L 20 11 L 19.5 14 L 16 14 L 16 24 L 21.800781 24 C 23 24 24 23 24 21.800781 L 24 2.199219 C 24 1 23 0 21.800781 0 Z" />
    </IconSvg>
  </a>
)

export default FacebookIcon
